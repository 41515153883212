@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

/* Transparencia a los textos del Slider */
.slider-transparency {
  opacity: 0.5;
}

.slider-content {
  text-align: center;
  background-size: cover !important;
  width: 100%;
  height: 100vh;
}

.text {
  padding-top: 35px;
  background: rgba(223, 223, 223, 0.521);
  height: 340px;
}

.text h1 {
  font-size: 40px !important;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff,
    -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.text p {
  padding-left: 30px;
  padding-right: 30px;
  color: white !important;
  font-size: 15px !important;
}

.slider-content .inner {
  padding: 0;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: black;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

/* .carousel {
} */

.slide {
  min-height: 100px;
  opacity: 0.9;
}

.selected {
  box-shadow: 15px 0 15px -4px rgba(31, 73, 125, 0.8), -15px 0 15px -4px rgba(31, 73, 125, 0.8);
  opacity: 1;
}

.carousel .slide .legend {
  background: #630c08;
  color: white;
  padding: 0px;
}

.control-dots {
  padding-left: 0px;
}

.carousel.carousel-slider .control-arrow {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: #a72529 !important;
  opacity: 0.8 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.carousel .slide button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  color: white;
  background: #a72529;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
}

.carousel .slide button:hover {
  color: #ffffff;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.slider-content {
  text-align: center;
  background-size: cover !important;
  width: 100%;
  height: 100vh;
}

.carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #630c08;
  color: white;
  padding: 0px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

.carousel:hover .slide .legend {
  opacity: 0.4;
}

.slide h1 {
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 1;
}

.slide p {
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 1;
}

.slide button {
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 1;
}
